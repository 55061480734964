import {Component} from '@angular/core';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';

@Component({
    selector: 'app-topbar',
    template: `
        <div class="layout-topbar">
            <div class="layout-topbar-left">
                <a href="#" class="topbar-menu-button" (click)="appMain.onMenuButtonClick($event)" *ngIf="appMain.isOverlay() || appMain.isMobile()">
                    <i class="pi pi-bars"></i>
                </a>

                <a href="#" class="logo">
                    <img  [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'">
                </a>

                <a href="#">
                    <img  [src]="'assets/layout/images/appname-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'" class="app-name"/>
                </a>
            </div>

            <app-menu></app-menu>

            <div class="layout-topbar-right">
                <ul class="layout-topbar-right-items">

                    <li #profile class="profile-item" [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === profile}">
                        <a href="#" (click)="appMain.onTopbarItemClick($event,profile)">
                            <img src="assets/layout/images/profile-image.png">
                        </a>

                        <ul class="fadeInDown">
                            <li role="menuitem">
                                 <a href="#" (click)="appMain.profile($event)">
                                    <i class="pi pi-fw pi-user"></i>
                                    <span>Profile</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                 <a href="#" (click)="appMain.cuti($event)">
                                    <i class="pi pi-fw pi-check-circle"></i>
                                    <span>Pengajuan Cuti</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="appMain.popPassword($event)"><i
                                        class="pi pi-fw pi-lock"></i><span>Password</span></a>
                            </li>
                            <!-- <li role="menuitem">
                                <a href="#" (click)="appMain.onTopbarSubItemClick($event)">
                                    <i class="pi pi-fw pi-cog"></i>
                                    <span>Settings</span>
                                </a>
                            </li> -->
                            <li role="menuitem">
                                <a href="#" (click)="appMain.logout()">
                                    <i class="pi pi-fw pi-sign-out"></i>
                                    <span>Logout</span>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li #notifications [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === notifications}">
                        <a href="#" (click)="appMain.onTopbarItemClick($event,notifications)">
                            <i class="topbar-icon pi pi-fw pi-bell"></i>
                            <span class="topbar-badge">{{appMain.jmlNotif}}</span>
                            <span class="topbar-item-name">Notifications</span>
                        </a>

                        <ul class="fadeInDown notif" style="min-width: 260px;max-height: 500px; overflow: auto;">
                            <li class="layout-submenu-header"><h6 class="header-text">Notifications</h6><span class="p-badge">{{appMain.jmlNotif}}</span></li>
                            <li role="menuitem" *ngFor="let notif_item of appMain.listNotif">
                                <a href="#" (click)="appMain.onSelectNotif($event,notif_item)">
                                     <!-- <i class="pi pi-fw pi-comment notif-icon"></i> -->
                                    <div class="messages-item">
                                        <h6 style="margin:0;">{{notif_item.judul}}</h6>
                                        <span>{{notif_item.jenis}}</span>
                                        <p style="font-size: 10px;color: rgba(81, 92, 102, 0.6);"> {{notif_item.tgl_string}}</p>
                                    </div>
                                </a>
                            </li>

                            <li role="menuitem"  *ngIf="appMain.listNotif.length == 0">
                                <a href="#" (click)="appMain.onTopbarSubItemClick($event)">
                                     <!-- <i class="pi pi-fw pi-comment notif-icon"></i> -->
                                    <div class="messages-item">
                                        <h6 style="margin:0;">Tidak Ada</h6>
                                        <span>Judul</span>
                                    </div>
                                </a>
                            </li>


                        </ul>
                    </li>
                    <li>
                        <a href="#">
                            <i class="topbar-icon pi pi-fw pi-comment"></i>
                            <span class="topbar-badge">0</span>
                            <span class="topbar-item-name">Messages</span>
                        </a>
                    </li>
                    <li *ngIf="!appMain.darkMode">
                        <a href="#"  (click)="appMain.onChangeTheme($event,'dark')">
                            <i class="topbar-icon pi pi-fw pi-moon"></i>
                            <span class="topbar-item-name">Messages</span>
                        </a>
                    </li>
                    <li *ngIf="appMain.darkMode">
                        <a href="#"  (click)="appMain.onChangeTheme($event,'light')">
                            <i class="topbar-icon pi pi-fw pi-sun"></i>
                            <span class="topbar-item-name">Messages</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    `,

        styles:[`
        .notif-icon {
            background-color: rgba(47, 142, 229, 0.2);
            width: 28px;
            height: 28px;
            margin-right: 6px;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: center;
            justify-content: center;
            font-size: 16px;
        }
         li > ul > li > a > div > span {
            color: rgba(81, 92, 102, 0.6);
        }

        .p-badge {
            display: inline-block;
            border-radius: 10px;
            text-align: center;
            padding: 0 0.5rem;
        }
        .p-badge {
            font-size: 0.75rem;
            font-weight: 700;
            min-width: 1.5rem;
            height: 1.5rem;
            line-height: 1.5rem;
        }
        li > ul .layout-submenu-header {
            padding: 12px;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: justify;
            justify-content: space-between;
            background: rgb(81 92 102 / 10%);
        }

         li > ul .layout-submenu-header h6 {
            font-weight: 600;
            margin: 0;
            margin-left: 4px;
        }`]
})
export class AppTopbarComponent {
    constructor(public app: AppComponent, public appMain: AppMainComponent) {}

}
